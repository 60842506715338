import { api, hybrid, onLocalStorageChange } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { watch } from 'vue'

const RADIO_TOKEN_LOCAL_STORAGE_KEY = 'radio-auth-token'

export const useUserStore = defineStore('user', {
  state: () => ({
    radioToken: null,
    askingForLogin: false,
    profile: null,
  }),
  actions: {
    initialize() {
      hybrid.appLoaded().then((radioToken) => {
        if (radioToken) {
          this.setToken(radioToken)
        }
      })
      hybrid.on('authenticated', ({ radioToken }) => {
        this.setToken(radioToken)
      })
      onLocalStorageChange(RADIO_TOKEN_LOCAL_STORAGE_KEY, (token) => this.setToken(token), true)
    },
    setToken(token) {
      this.radioToken = token

      api.setRadioToken(token)

      if (token) {
        this.askingForLogin = false
      }
    },
    askForLogin() {
      this.askingForLogin = true

      if (import.meta.env.DEV) {
        this.setToken(prompt('[DEVELOPMENT] Please enter your radio token:'))
        return
      }
      if (hybrid.isNativeApp()) {
        hybrid.call('showAuthentication', { tier: 'light' })
        return
      }
      window.location.href = '/login'
    },
    requireAuthentication() {
      return new Promise((resolve, reject) => {
        if (this.radioToken) {
          return resolve()
        }
        const stopWatch = watch(
          () => [this.askingForLogin, this.radioToken],
          () => {
            this.radioToken ? resolve() : reject('There is no authenticated user.')
            stopWatch()
          }
        )
        this.askForLogin()
      })
    },
    async fetchProfile() {
      if (!this.profile) {
        this.profile = await api.members.me()
      }
      return this.profile
    },
  },
})
