<script setup>
import { hybrid } from '@dpgradio/creative'

const navigateToInApp = (url) => hybrid.call('navigateTo', { url, inApp: true })
const navigateToOutsideApp = (url) => hybrid.call('navigateTo', { url, inApp: false })

const urls = [
  {
    name: 'Ext',
    url: 'https://hybrid-tester.pages.dev/?stationId=joe_nl',
  },
  {
    name: 'Article Abs',
    url: 'https://joe.nl/coen-en-sander-komen-naar-joe-voor-ochtendshow',
  },
  {
    name: 'Article AbsW',
    url: 'https://www.joe.nl/coen-en-sander-komen-naar-joe-voor-ochtendshow',
  },
  {
    name: 'Article Rel',
    url: '/coen-en-sander-komen-naar-joe-voor-ochtendshow',
  },
  {
    name: 'HybDet Abs',
    url: 'https://joe.nl/app-chart-hybrid/371/5689--share_url=123',
  },
  {
    name: 'HybDet AbsW',
    url: 'https://www.joe.nl/app-chart-hybrid/371/5689--share_url=123',
  },
  {
    name: 'HybDet Rel',
    url: '/app-chart-hybrid/371/5689--share_url=123',
  },
]
</script>

<template>
  <table cellpadding="2" class="leading-none text-xs">
    <tr>
      <td class="w-4">navTo, inApp</td>
      <td v-for="url in urls" :key="url.url">
        <button class="w-6 h-6 bg-blue text-white" @click="navigateToInApp(url.url)">{{ url.name }}</button>
      </td>
    </tr>
    <tr>
      <td>navTo</td>
      <td v-for="url in urls" :key="url.url">
        <button class="w-6 h-6 bg-purple text-white" @click="navigateToOutsideApp(url.url)">{{ url.name }}</button>
      </td>
    </tr>
    <tr>
      <td>a href</td>
      <td v-for="url in urls" :key="url.url">
        <a :href="url.url">
          <button class="w-6 h-6 bg-yellow">{{ url.name }}</button>
        </a>
      </td>
    </tr>
    <tr>
      <td>a href blank</td>
      <td v-for="url in urls" :key="url.url">
        <a :href="url.url" target="_blank">
          <button class="w-6 h-6 bg-teal text-white">{{ url.name }}</button>
        </a>
      </td>
    </tr>
  </table>
  <a href="https://joe.nl/hybrid/coen-en-sander-komen-naar-joe-voor-ochtendshow">
    TEMP - Open this tester as a hybrid detail
  </a>
</template>

<style scoped>
table,
button,
td {
  padding: 0;
  margin: 0;
}

table,
button {
  font-size: 0.75rem;
  line-height: 1;
  font-family: 'Cervo';
}

button {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  border: 0;
}

.bg-blue {
  background-color: #3b82f6;
}
.bg-purple {
  background-color: #8a63d2;
}
.bg-yellow {
  background-color: #fbbf24;
}
.bg-teal {
  background-color: #14b8a6;
}
</style>
