<script setup>
import { config } from '@dpgradio/creative'
import LoginExample from './components/LoginExample.vue'
import UrlTest from './components/UrlTest.vue'
import UrlTestNew from './components/UrlTestNew.vue'
import EventLog from './components/EventLog.vue'
import ChangeHeight from './components/ChangeHeight.vue'

const currentVersion = import.meta.env.VITE_COMMIT_HASH

const apiBaseUrl = config('api_base_url')

const urlParams = new URLSearchParams(window.location.search)
const only = urlParams.get('only')

const isOnly = (name) => only === name || !only
</script>

<template>
  <section v-if="isOnly('config')">
    <h1>Config</h1>
    <p>API base URL: {{ apiBaseUrl }}</p>
  </section>
  <section v-if="isOnly('authentication')">
    <h1>Authentication</h1>
    <LoginExample />
  </section>
  <section v-if="isOnly('openLink')">
    <h1>Open Link [old style]</h1>
    <UrlTest />
  </section>
  <section v-if="isOnly('openLinkNew')">
    <h1>Open Link [new style]</h1>
    <UrlTestNew />
  </section>
  <section v-if="isOnly('changeHeight')">
    <h1>Change Height</h1>
    <ChangeHeight />
  </section>
  <section v-if="isOnly('eventLog')">
    <h1>Event Log</h1>
    <EventLog />
  </section>
  <section v-if="isOnly('hybridTester')">
    <h1>Hybrid Tester - About</h1>
    <p>Current version: {{ currentVersion }}</p>
    <p>All sections are available separately at `?only=camelCasedTitle`.</p>
    <p>Either use the full URL of this page or embed it using the following embed code:</p>
    <!-- eslint-disable-next-line prettier/prettier -->
    <textarea disabled><div id="hybrid-tester"></div><script>try{import("_").catch(function(){})}catch(t){}window.importSupported=true;</script>  <script>var s;window.importSupported?import("https://hybrid-tester.pages.dev/assets/index.js").then(function(){window.createVueApp()}):((s=document.createElement("script")).onload=function(){System.import("https://hybrid-tester.pages.dev/assets/index-legacy.js")},s.src="https://hybrid-tester.pages.dev/assets/polyfills-legacy.js",document.head.appendChild(s));</script></textarea>
  </section>
</template>

<style>
html,
body {
  background: transparent;
}

h1,
h2 {
  font-family: 'Cervo';
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.5rem;
  padding: 0;
  margin: 0 0 0.2rem 0;
}

section {
  margin-bottom: 1rem;
}

p {
  font-family: 'Qarla';
  padding: 0;
  margin: 0;
}
</style>
