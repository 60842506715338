<script setup>
import { hybrid } from '@dpgradio/creative'

const events = []

const logEvent = (method, args = {}) => {
  events.push(`${new Date()} - ${method} - ${JSON.stringify(args)}`)
}

hybrid.on('appLoad', logEvent)
hybrid.on('authenticated', logEvent)
hybrid.on('didAppear', logEvent)
hybrid.on('didHide', logEvent)
</script>

<template>
  <div>
    <pre v-for="event in events" :key="event">{{ event }}</pre>
  </div>
</template>

<style scoped>
div {
  font-size: 0.6rem;
  border: 1px solid gray;
  padding: 0.5rem;
}
</style>
