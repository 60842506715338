<script setup>
import { ref } from 'vue'
import { useUserStore } from '../stores/user'
import { hybrid } from '@dpgradio/creative'

const user = useUserStore()

user.initialize()

const actionCompleted = ref(false)
const secretAction = async () => {
  await user.requireAuthentication()

  await user.fetchProfile()

  actionCompleted.value = true
}

const showAuthentication = async () => {
  hybrid.call('showAuthentication', { tier: 'light' })
}
</script>

<template>
  <div>
    <p><strong>Action based on state:</strong></p>
    <p v-if="user.askingForLogin">Login in progres...</p>
    <button v-else-if="!actionCompleted" @click="secretAction">Secret action that requires login</button>
    <p v-else>Welcome {{ user.profile.first_name }}</p>
  </div>
  <div>
    <p><strong>Action regardless of state:</strong></p>
    <button @click="showAuthentication">showAuthentication</button>
  </div>
</template>
