import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configuration, setupAirbrake, dataLayer, privacy } from '@dpgradio/creative'
import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import './style.css'
import App from './App.vue'

const initialize = async () => {
  try {
    await configuration.retrieveConfigForDetectedStation()
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'hybrid-tester')] in [main.js]. 

      ${error}
    `)
  }

  const pinia = createPinia()
  const app = createApp(App)

  if (import.meta.env.PROD) {
    await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: '<project-id>',
        projectKey: '<project-key>',
        version: import.meta.env.VITE_COMMIT_HASH,
      },
      app
    )
  }

  app.use(pinia)
  app.mount('#hybrid-tester')

  privacy.initialize()
  dataLayer.initialize()
  dataLayer.pushVirtualPageView()
}

initialize()
