<script setup>
import { hybrid } from '@dpgradio/creative'
import { ref } from 'vue'

const currentHeight = ref(window.innerHeight)

window.addEventListener('resize', () => {
  currentHeight.value = window.innerHeight
})

const setHeight = (height) => {
  hybrid.call('changeHeight', { height, animated: animated.value })
}

const animated = ref(true)
</script>

<template>
  <p>The current height is: {{ currentHeight }}</p>
  <div>
    <button @click="setHeight(300)">Set to 300</button>
    <button @click="setHeight(currentHeight + 200)">Increase by 200</button>
    <button @click="setHeight(currentHeight - 100)">Decrease by 100</button>
  </div>
  <p>
    <label>
      <input v-model="animated" type="checkbox" />
      Animated
    </label>
  </p>
</template>

<style scoped>
button {
  margin-right: 0.1rem;
}
</style>
