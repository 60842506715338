<script setup>
import { hybrid } from '@dpgradio/creative'

const openUrl = (url, mode) => hybrid.call('openUrl', { url, mode })
const openPermalink = (permalink) => hybrid.call('openPermalink', { permalink })

const modes = ['segue', 'overlay', 'in-app-browser', 'external-browser']

const urls = [
  {
    name: 'External',
    url: 'https://hybrid-tester.pages.dev/?stationId=joe_nl',
    aDescription: 'Should open in in-app browser.',
  },
  {
    name: 'Article Absolute URL',
    url: 'https://joe.nl/coen-en-sander-komen-naar-joe-voor-ochtendshow',
    aDescription: 'Should open as a native article in the app (deep link behavior).',
  },
  {
    name: 'Article Absolute URL with WWW',
    url: 'https://www.joe.nl/coen-en-sander-komen-naar-joe-voor-ochtendshow',
    aDescription: 'Should open as a native article in the app (deep link behavior).',
  },
  {
    name: 'Article Rel',
    url: '/coen-en-sander-komen-naar-joe-voor-ochtendshow',
    a: false,
  },
  {
    name: 'Hybrid Detail Absolute',
    url: 'https://joe.nl/app-chart-hybrid/371/5689--share_url=123',
    aDescription: 'Should open as a "native" hybrid detail in the app (deep link behavior).',
  },
  {
    name: 'Hybrid Detail Absolute with WWW',
    url: 'https://www.joe.nl/app-chart-hybrid/371/5689--share_url=123',
    aDescription: 'Should open as a "native" hybrid detail in the app (deep link behavior).',
  },
  {
    name: 'HybDet Rel',
    url: '/app-chart-hybrid/371/5689--share_url=123',
    a: false,
  },
]
</script>

<template>
  <p>This behavior should be the same across all different webviews.</p>

  <h3>hybrid.call('openUrl', { url: 'https://...', mode: '{choose}' })</h3>
  <button v-for="mode in modes" :key="mode" class="bg-blue" @click="openUrl(urls[0].url, mode)">
    {{ mode }}
  </button>

  <h3>hybrid.call('openPermalink', { permalink: '/...' })</h3>
  <p>Test the buttons below in the JOE NL production app. They should be processed like any other permalink.</p>
  <button class="bg-teal" @click="openPermalink(urls[3].url)">Article</button>
  <button class="bg-teal" @click="openPermalink(urls[6].url)">Hybrid Detail</button>

  <p>Test the buttons below in the QBE production app. They should be processed like any other permalink.</p>
  <button class="bg-teal" @click="openPermalink('/stem-jouw-heroes-in-de-top-500-van-de-00s')">Article</button>
  <button class="bg-teal" @click="openPermalink('/app-chart-hybrid/97/5705--share_url=123')">Hybrid Detail</button>

  <h3>a href</h3>
  <p>Test these in the JOE NL production app.</p>
  <div v-for="url in urls.filter((url) => url.a !== false)" :key="url.url">
    <div>
      <a :href="url.url">
        <button class="bg-purple">{{ url.name }}</button>
      </a>
    </div>
    {{ url.aDescription }}
    <hr />
  </div>
</template>

<style scoped>
h3 {
  font-size: 1.25rem;
  line-height: 1;
  font-family: 'Cervo';
}

button {
  height: 3rem;
  padding: 0 0.5rem;
  color: #fff;
  border: 0;

  font-size: 1rem;
  line-height: 1;
  font-family: 'Cervo';
}

button {
  margin: 0.2rem 0.2rem;
}

.bg-blue {
  background-color: #3b82f6;
}
.bg-purple {
  background-color: #8a63d2;
}
.bg-yellow {
  background-color: #fbbf24;
}
.bg-teal {
  background-color: #14b8a6;
}
</style>
